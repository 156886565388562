// Actions.js
import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Actions = ({ actions, sendMessage }) => {
  return (
    <div className="flex justify-center mb-4 space-x-2">
      {actions.map((action, index) => (
        <motion.button
          key={index}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="px-6 py-3 bg-white text-blue-500 rounded-md shadow-md font-semibold flex items-center border-2 border-blue-500"
          onClick={() => sendMessage(action)}
        >
          <span className="mr-2">{action}</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </motion.button>
      ))}
    </div>
  );
};

export default Actions;