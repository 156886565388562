// ChatBox.js
import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import Actions from './Actions';
import { Howl } from 'howler';

import taberna from '../audio/taberna.mp3';
import accion from '../audio/accion.mp3';
import suspense from '../audio/suspense.mp3';
import default1 from '../audio/default1.mp3';
import default2 from '../audio/default2.mp3';
import default3 from '../audio/default3.mp3';
import default4 from '../audio/default4.mp3';

const OPENAI_API_KEY = 'sk-proj-2qf66pjQzRhUTcV9KuBOT3BlbkFJ0XG8RQ2QXlp35c1CcZJO';

const ChatBox = ({ updateInventory, updateCharacterStats, updateActions, actions, inventory, characterStats }) => {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const chatBoxRef = useRef(null);
    const [currentMusic, setCurrentMusic] = useState(null);
    const [currentMusicName, setCurrentMusicName] = useState('default1');
    const [volume, setVolume] = useState(0.5); // Estado para el volumen
  
    useEffect(() => {
      scrollToBottom();
    }, [messages]);
  
    useEffect(() => {
      const sound = new Howl({
        src: [default1],
        autoplay: true,
        loop: true,
        volume: 0.5,
      });
      setCurrentMusic(sound);
    
      return () => {
        sound.unload();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // No incluir `volume` como dependencia
    
  
    const scrollToBottom = () => {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    };
  
    const playMusic = (music) => {
        if (currentMusic) {
          currentMusic.fade(currentMusic.volume(), 0, 3000); // Hacer fade out de la música actual durante 1 segundo
          setTimeout(() => {
            currentMusic.stop(); // Detener la música actual después del fade out
            currentMusic.unload(); // Descargar la música actual
          }, 5000); // Esperar 1 segundo para detener y descargar la música actual
        }
      
        const sound = new Howl({
          src: [music],
          autoplay: true,
          loop: true,
          volume: 0, // Comenzar con volumen 0
        });
      
        sound.once('load', () => {
          sound.fade(0, volume, 5000); // Hacer fade in de la nueva música durante 1 segundo hasta el volumen actual
        });
      
        setCurrentMusic(sound);
        setCurrentMusicName(music.split('/').pop().split('.')[0]);
      };

      const handleVolumeChange = (event) => {
        const newVolume = parseFloat(event.target.value);
        setVolume(newVolume);
        if (currentMusic) {
          currentMusic.volume(newVolume);
        }
      };
      

    const sendMessage = async (message) => {
        const newMessage = {
            role: 'user',
            content: message,
        };

        const updatedMessages = [...messages, newMessage];
        setMessages(updatedMessages);
        setInputMessage('');

        try {
            const response = await fetch('https://api.openai.com/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${OPENAI_API_KEY}`,
                },
                body: JSON.stringify({
                    model: 'gpt-3.5-turbo-0125',
                    response_format: { type: 'json_object' },
                    messages: [
                        {
                            role: 'system',
                            content: `Eres un asistente útil diseñado para guiar al usuario a través de un juego de rol interactivo basado en texto. El juego se desarrollará a través de una conversación entre el usuario y el asistente. El asistente proporcionará la historia y el contexto, y el usuario tomará decisiones y realizará acciones.
                            
                            Cuando el usuario envíe su primer mensaje, deberás comenzar la historia estableciendo la escena y dando poniendo en contexto de la historia al jugador, preguntale su nombre. Cuentale al jugador una breve historia sobre el mundo en el que habita. El universo debe ser fantastico y la region de inicio siempre debe ser llamada MiguelRosarioLand. A medida que la historia avance, deberás proporcionar opciones para que el usuario elija y adaptar la historia según sus elecciones.
                            
                            Tus respuestas deben estar en formato JSON con cuatro campos principales:
                            - "chat": Este campo contiene la historia, los diálogos y cualquier otro texto relacionado con la narrativa del juego.
                            - "inventory": Este campo es un array de objetos que representan los elementos que el personaje del usuario ha recolectado a lo largo del juego. Cada objeto debe tener tres propiedades:
                                - "name": El nombre del objeto (string).
                                - "quantity": La cantidad del objeto (number).
                                - "icon": El nombre del icono de Font Awesome que representa al objeto (string), sin el prefijo "fa-". Elige el icono más adecuado para cada objeto.
                            - "characterStats": Este campo es un objeto que contiene las estadísticas del personaje con los siguientes campos exactos:
                                - "name": El nombre del personaje (string).
                                - "level": El nivel actual del personaje (number).
                                - "experience": La experiencia actual del personaje (number).
                                - "health": La vida actual del personaje (number).
                                - "energy": La energía actual del personaje (number).
                            - "actions": Este campo es un array de strings que representan las acciones que el usuario puede tomar en ese momento de la historia, MUESTRALO VACIO SI NO ES RELEVANTE EN LA HISTORIA. Cada acción debe ser una descripción corta y concisa de lo que el usuario puede hacer. No incluyas más de 4 acciones a la vez. No muestras acciones si no es relevante ya que el usuaria podra escribir, solo cuando se le plantee una toma de decisión.
                            - "music": En este campo el nombre del archivo de audio que mejor se adapte a la situación actual de la historia. Las opciones de música son: "taberna", "accion", "suspense", "default1", "default2", "default3" y "default4". Si no hay un cambio significativo en la historia, puedes dejar la música que actualmente está sonando.
                            
                            Actualiza estos valores según sea necesario a medida que avanza la historia.
                            
                            Recuerda mantener la historia atractiva, proporcionar opciones significativas y actualizar el inventario, las estadísticas del personaje y las acciones disponibles según sea necesario. Los mensajes anteriores del usuario se proporcionarán para dar contexto, así que utiliza esa información para mantener una historia consistente y coherente.`,
                        },
                        ...updatedMessages.slice(-10), // Limitar el contexto a los últimos 10 mensajes
                        {
                          role: 'user',
                          content: `${message}\n\nInventario actual: ${JSON.stringify(inventory)}\nEstadísticas del personaje: ${JSON.stringify(characterStats)}`,
                        },
                      ],
                    }),
                  });

                  console.log('Mensajes:', updatedMessages.slice(-10)); // Mostrar los últimos 10 mensajes
                  console.log('Inventario:', inventory); // Mostrar el inventario actual
                  console.log('Estadísticas del personaje:', characterStats); // Mostrar las estadísticas del personaje

            if (!response.ok) {
                throw new Error(`HTTP error ${response.status}`);
            }

            const data = await response.json();
            const responseContent = JSON.parse(data.choices[0].message.content);
            console.log('Respuesta de OpenAI:', responseContent);

            const responseMessage = {
                role: 'assistant',
                content: responseContent.chat,
            };

            setMessages([...updatedMessages, responseMessage]);

            updateInventory(responseContent.inventory);
            updateCharacterStats(responseContent.characterStats);
            updateActions(responseContent.actions);

      // Reproducir la música según la respuesta de la IA
      if (responseContent.music && responseContent.music !== currentMusicName) {
        switch (responseContent.music) {
          case 'taberna':
            playMusic(taberna);
            break;
          case 'accion':
            playMusic(accion);
            break;
          case 'suspense':
            playMusic(suspense);
            break;
          case 'default1':
            playMusic(default1);
            break;
          case 'default2':
            playMusic(default2);
            break;
          case 'default3':
            playMusic(default3);
            break;
          case 'default4':
            playMusic(default4);
            break;
          default:
            if (currentMusic) {
              currentMusic.stop(); // Detener la música actual si no se especifica una nueva
            }
            break;
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="bg-gray-200 bg-opacity-80 shadow-lg rounded-lg overflow-hidden">
      <div
        ref={chatBoxRef}
        className="h-[500px] overflow-y-scroll p-6"
      >
        {messages.map((message, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, ease: 'easeOut' }}
            className={`my-4 ${
              message.role === 'user' ? 'text-right' : 'text-left'
            }`}
          >
            <div
              className={`inline-block px-4 py-2 rounded-lg shadow-md text-lg ${
                message.role === 'user'
                  ? 'bg-blue-500 text-white'
                  : 'bg-white text-gray-800'
              }`}
              dangerouslySetInnerHTML={{ __html: formatMessage(message.content) }}
            />
          </motion.div>
        ))}
      </div>
      <div className="p-6">
        <Actions actions={actions} sendMessage={sendMessage} />
        <div className="flex items-center">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && sendMessage(inputMessage)}
            className="flex-1 px-4 py-2 bg-white text-gray-800 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-lg"
            placeholder="Escribe lo que quieras, es tu aventura..."
          />
          <button
            onClick={() => sendMessage(inputMessage)}
            className="px-6 py-2 bg-blue-500 text-white rounded-r-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            <FontAwesomeIcon icon={faPaperPlane} className="text-xl" />
          </button>
        </div>
        <div className="mt-4">
          <label htmlFor="volume-slider" className="mr-2">Volumen:</label>
          <input
            id="volume-slider"
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
};

const formatMessage = (content) => {
    // Convertir content a una cadena de texto
    content = content.toString();

    // Aplica negrita a los textos entre asteriscos
    content = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Aplica cursiva a los textos entre asteriscos simples
    content = content.replace(/\*(.*?)\*/g, '<em>$1</em>');

    // Añade saltos de línea
    content = content.replace(/\n/g, '<br>');

    return content;
};

export default ChatBox;
