// App.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import ChatBox from './components/ChatBox';
import Inventory from './components/Inventory';
import Character from './components/Character';

const App = () => {
  const [inventory, setInventory] = useState([]);
  const [characterStats, setCharacterStats] = useState({
    name: '',
    level: 1,
    experience: 0,
    health: 100,
    energy: 100,
  });
  const [actions, setActions] = useState([]);
  const [showWelcome, setShowWelcome] = useState(true);

  useEffect(() => {
    const storedInventory = localStorage.getItem('inventory');
    if (storedInventory) {
      setInventory(JSON.parse(storedInventory));
    }

    const storedCharacterStats = localStorage.getItem('characterStats');
    if (storedCharacterStats) {
      setCharacterStats(JSON.parse(storedCharacterStats));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('inventory', JSON.stringify(inventory));
    localStorage.setItem('characterStats', JSON.stringify(characterStats));
  }, [inventory, characterStats]);

  const updateInventory = (newInventory) => {
    setInventory(newInventory);
  };

  const updateCharacterStats = (newStats) => {
    setCharacterStats(newStats);
  };

  const updateActions = (newActions) => {
    setActions(newActions);
  };

  const handleStartAdventure = () => {
    setShowWelcome(false);
    // Enviar mensaje de inicio de aventura a ChatBox
    const chatBox = document.querySelector('.chat-box');
    if (chatBox) {
      chatBox.dispatchEvent(new CustomEvent('startAdventure'));
    }
  };

  return (
    <div className="min-h-screen bg-cover bg-center" style={{ backgroundImage: "url('/images/background.jpg')" }}>
      <header className="bg-gray-800 bg-opacity-80 text-white py-4 px-6">
        <motion.h1
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className="text-2xl font-bold text-center"
        >
          Charcher<span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">Engine</span>
        </motion.h1>
      </header>
      <div className="container mx-auto py-12">
        {showWelcome ? (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-gray-200 bg-opacity-80 shadow-lg rounded-lg p-6 mb-6"
          >
            <h2 className="text-4xl font-bold mb-4 text-center text-gray-800">¡Bienvenido a Pollazos<span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">Legends</span>!</h2>
            <p className="text-lg text-gray-700 mb-6 text-center">Prepárate para embarcarte en una emocionante aventura llena de lefazos. Putas y Putorras.</p>
            <p className="text-lg text-gray-700 mb-6 text-center">El mundo es tuyo, pero de nadie al mismo tiempo, jesus te ama.</p>

            <div className="flex justify-center">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleStartAdventure}
                className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg shadow-lg font-semibold text-xl"
              >
                Comenzar Aventura
              </motion.button>
            </div>
          </motion.div>
        ) : (
          <div className="grid grid-cols-12 gap-8">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="col-span-4"
            >
              <Character characterStats={characterStats} />
              <Inventory items={inventory} />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="col-span-8"
            >
              <ChatBox
                updateInventory={updateInventory}
                updateCharacterStats={updateCharacterStats}
                updateActions={updateActions}
                actions={actions}
                inventory={inventory}
                characterStats={characterStats}
              />
            </motion.div>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;