// Inventory.js
import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import * as SolidIcons from '@fortawesome/free-solid-svg-icons';

const Inventory = ({ items }) => {
  const getIcon = (iconName) => {
    const transformedIconName = `fa${iconName.charAt(0).toUpperCase()}${iconName.slice(1)}`;
    const icon = SolidIcons[transformedIconName];
    return icon || faQuestionCircle; // Fallback to a default icon if not found
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="bg-gray-200 bg-opacity-80 shadow-lg rounded-lg p-6 mt-6">
      <h2 className="text-2xl font-bold mb-4 flex items-center text-gray-800">
        <FontAwesomeIcon icon={faBox} className="mr-2 text-blue-500" />
        Inventario
      </h2>
      <ul className="space-y-2">
        {items.map((item, index) => (
          <motion.li
            key={index}
            className="bg-white rounded-lg py-3 px-4 text-gray-800 flex items-center"
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: 100, opacity: 0 }}
              transition={{ duration: 0.5, ease: 'easeOut' }}
              className="mr-2"
            >
              <FontAwesomeIcon icon={getIcon(item.icon)} className="text-blue-500" />
            </motion.div>
            {item.name} x{item.quantity}
          </motion.li>
        ))}
      </ul>
    </div>
  );
};

export default Inventory;