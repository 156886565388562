// Character.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const Character = ({ characterStats }) => {
  const { name, level, experience, health, energy } = characterStats;

  return (
    <div className="bg-gray-200 bg-opacity-80 shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-4 flex items-center text-gray-800">
        <FontAwesomeIcon icon={faUser} className="mr-2 text-blue-500" />
        Personaje
      </h2>
      <div className="space-y-2">
        <p className="text-lg text-gray-800">Nombre: <span className="font-semibold">{name}</span></p>
        <p className="text-lg text-gray-800">Nivel: <span className="font-semibold">{level}</span></p>
        <p className="text-lg text-gray-800">Experiencia: <span className="font-semibold">{experience}</span></p>
        <p className="text-lg text-gray-800">Vida: <span className="font-semibold">{health}</span></p>
        <p className="text-lg text-gray-800">Energía: <span className="font-semibold">{energy}</span></p>
      </div>
    </div>
  );
};

export default Character;